import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"id":"home"}},[_c(VRow,_vm._l((_vm.menus),function(menu){return _c(VCol,{key:menu.link,attrs:{"cols":"3"}},[(menu.link === 'indicadores.game' || _vm.uf === 'SC')?_c(VCard,{staticClass:"mx-auto",attrs:{"to":{ name: menu.link }}},[_c(VImg,{attrs:{"src":menu.icone,"height":"200px"}}),_c(VCardText,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,[_c('h1',[_vm._v(_vm._s(menu.texto))])])],1)],1)],1):_vm._e()],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }