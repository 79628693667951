<template>
  <v-container id="home">
    <v-row>
      <v-col
        v-for="menu in menus"
        :key="menu.link"
        cols="3"
      >
        <v-card
          v-if="menu.link === 'indicadores.game' || uf === 'SC'"
          class="mx-auto"
          :to="{ name: menu.link }"
        >
          <v-img
            :src="menu.icone"
            height="200px"
          />
          <v-card-text>
            <v-row align="center">
              <v-col>
                <h1>{{ menu.texto }}</h1>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default ({
  data () {
    return {
      menus: [
        { texto: 'Indicadores da Gameficação', link: 'indicadores.game', icone: '/assets/img/indicador3.jpg' },
        { texto: 'Indicadores de Execução', link: 'indicadores.execucao', icone: '/assets/img/indicador1.jpg' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'isLogged',
      'isAuthenticated',
      'isConsultor',
      'isAdmin',
      'loading',
      'user',
      'uf',
      'logo',
      'svg'
    ])
  }
})
</script>

<style lang="scss">@import '../style.scss';</style>
